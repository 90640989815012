<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        If you start with a
        <number-value :value="concentration" unit="\text{M}" />
        aqueous solution of
        <stemble-latex content="$\ce{MgSO3,}$" /> determine the concentration of each species and
        the pH of the solution when the system reaches equilibrium. For parts a) - e), please
        express your answers to 3 significant figures. Please use 2 significant figures in your
        answer for part f).
      </p>

      <calculation-input
        v-model="inputs.mgConc"
        class="mb-3"
        prepend-text="$\ce{a) [Mg^{2+}]}\hspace{0.23cm}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.bConc"
        class="mb-3"
        prepend-text="$\ce{b) [SO3^2-]}\hspace{0.10cm}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.bhConc"
        class="mb-3"
        prepend-text="$\ce{c) [HSO3-]}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.bh2Conc"
        class="mb-3"
        prepend-text="$\ce{d) [H2SO3]}\hspace{0.02cm}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.h3oConc"
        class="mb-3"
        prepend-text="$\ce{e) [H3O^+]}\hspace{0.17cm}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.pH"
        prepend-text="$\text{f) pH}\hspace{0.83cm}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question470',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        mgConc: null,
        bConc: null,
        bhConc: null,
        bh2Conc: null,
        h3oConc: null,
        pH: null,
      },
    };
  },
  computed: {
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content;
    },
  },
};
</script>
